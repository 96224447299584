import { render, staticRenderFns } from "./BaldanCollectionUpdate.vue?vue&type=template&id=71b4ebeb&scoped=true&"
import script from "./BaldanCollectionUpdate.vue?vue&type=script&lang=js&"
export * from "./BaldanCollectionUpdate.vue?vue&type=script&lang=js&"
import style0 from "./BaldanCollectionUpdate.vue?vue&type=style&index=0&id=71b4ebeb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b4ebeb",
  null
  
)

export default component.exports